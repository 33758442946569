import '../assets/css/customCarousel.scss';
import '../assets/css/customPlayer.scss';
import '../assets/css/tailwind.css';
import '../assets/css/textRollAnimation.scss';
import '../components/Shared/Carousel.css';
import '../components/Shared/ReactModal.css';
import '../styles/blog-page.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

import { NextSeo } from 'next-seo';
import { OverlayProvider } from '../components/Blog/contexts/overlayProvider';
import { PageNames, detectWebView } from '../utils/generalUtilities';
import { PostHogProvider } from 'posthog-js/react';
import { RecoilRoot } from 'recoil';
import { SlideShowProvider } from '../contexts/SlideShowContext';
import { ThemeProvider } from '../components/Blog/contexts/themeProvider';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from '../hooks/useUser';
import { metaTag } from '../assets/generalAssets';
import { processEnv } from '../lib/blog/processEnv';
import DashboardHeader from '@/components/Shared/DashboardHeader';
import DocumentHead, {
  MetaTagKeys,
  TWITTER_HANDLE,
  description,
  title
} from '../components/DocumentHead';
import Head from 'next/head';
import React, { useEffect, useMemo, useState } from 'react';
import ReactHelmet from 'react-helmet';
import SideNavbar from '@/components/SideNavbar/SideNav';
import posthog from 'posthog-js';

const TOASTER_DELAY_IN_MS = 7000; // default 5 seconds, ie 5000ms

const SeoStuff: React.FC = () => {
  const isGhostPages =
    typeof window !== 'undefined' &&
    window.location.pathname.startsWith(PageNames.BLOG);
  const isWaitlist =
    typeof window !== 'undefined' &&
    window.location.pathname.includes('waitlist');

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2.0, user-scalable=no"
        />
        <meta name="google" content="notranslate" />
      </Head>
      {!isGhostPages && (
        <NextSeo
          title={title}
          description={description}
          openGraph={{
            title: title,
            description: description,
            images: isWaitlist
              ? []
              : [
                  {
                    url: metaTag,
                    width: 1200,
                    height: 400
                  }
                ]
          }}
          twitter={{
            handle: TWITTER_HANDLE,
            site: TWITTER_HANDLE,
            cardType: MetaTagKeys.TWITTER_SUMMARY_LARGE_IMAGE
          }}
          additionalLinkTags={[
            {
              rel: 'icon',
              href: 'https://d3enyft5v5gx6r.cloudfront.net/website/SEO/favicon.png'
            }
          ]}
          additionalMetaTags={[
            {
              property: MetaTagKeys.OG_TYPE,
              content: 'website'
            }
          ]}
        />
      )}
    </>
  );
};

const MyApp: React.FC<{ Component: any; pageProps: any }> = ({
  Component,
  pageProps
}) => {
  const [ready, setReady] = useState(false);
  const [link, setLink] = useState<
    Array<{ rel: string; type: string; href: string }>
  >([]);

  const initializePosthog = () => {
    if (typeof window !== 'undefined') {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: '/ingest',
        ui_host: 'https://us.i.posthog.com',
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
        }
      });
    }
  };

  const setup = async () => {
    setReady(true);
    detectWebView();

    // Dynamically import stylesheets
    (await import('../assets/css/global.scss')).default;
    // const globalStyles = new GlobalStyles();
    addHref('../assets/css/global.scss');
    if (window.location.pathname !== '/') {
      (await import('../assets/css/style.scss')).default;
      addHref('../assets/css/style.scss');
      // addHref((await import('../assets/css/style.scss')).default);
    }
  };

  useEffect(() => {
    initializePosthog();
    setup();
  }, []);

  const addHref = (href: string) => {
    setLink((prevLink) => [
      ...prevLink,
      {
        rel: 'stylesheet',
        type: 'text/css',
        href
      }
    ]);
  };

  const renderHelmet = useMemo(() => {
    return link.length > 0 ? <ReactHelmet link={link} /> : null;
  }, [link]);

  return (
    <div translate="no">
      <PostHogProvider client={posthog}>
        {renderHelmet}
        <SeoStuff />
        <DocumentHead />
        <React.Fragment>
          <ToastContainer
            icon={false}
            theme="light"
            limit={3}
            autoClose={TOASTER_DELAY_IN_MS}
          />
          <div
            className={`${
              ready ? 'relative flex flex-col' : 'hidden'
            } h-screen overflow-x-hidden`}
          >
            <ThemeProvider {...processEnv.darkMode}>
              <RecoilRoot>
                <OverlayProvider>
                  <UserProvider>
                    <SlideShowProvider>
                      <DashboardHeader />
                      <div className="flex h-full w-screen flex-row overflow-hidden">
                        <SideNavbar />
                        <div className="no-scrollbar w-full overflow-scroll">
                          <Component {...pageProps} />
                        </div>
                      </div>
                    </SlideShowProvider>
                  </UserProvider>
                </OverlayProvider>
              </RecoilRoot>
            </ThemeProvider>
          </div>
        </React.Fragment>
      </PostHogProvider>
    </div>
  );
};

export default MyApp;
