import { IS_DEV_ENV } from '../models/constant';

const findPlanNameByProductId = (productId: string) => {
  if (!IS_DEV_ENV) {
    if (productId === 'prod_MeLsOwNB4yGHFE') return 'starter';
    if (productId === 'prod_OO7yVEZfh0LowC') return 'starter';
    if (productId === 'prod_MeLs9lCuZpengp') return 'creator';
    if (productId === 'prod_OO7zUw4co10Xa9') return 'creator';
    if (productId === 'prod_OO818Tr1hN9j7S') return 'pro';
  }
  if (productId === 'prod_Majcad01iZpInm') return 'starter';
  if (productId === 'prod_OOAsO1LIJptXhy') return 'starter';
  if (productId === 'prod_MajpoF4nPXOVcB') return 'creator';
  if (productId === 'prod_OOAsOMp6BvQ3kY') return 'creator';
  if (productId === 'prod_OOAtDgP5XJeXGg') return 'pro';
  return null;
};

const STARTER_PLAN = 'starter';
const CREATOR_PLAN = 'creator';
const PRO_PLAN = 'pro';
const APPSUMO_TIER1 = 'jupitrr_tier1';
const APPSUMO_TIER2 = 'jupitrr_tier2';
const APPSUMO_TIER3 = 'jupitrr_tier3';
const FREE_PLAN = 'free';


const findPlanNameByAppSumoProductId = (productId: string) => {
  if (productId === APPSUMO_TIER1) return 'Appsumo Tier 1';
  if (productId === APPSUMO_TIER2) return 'Appsumo Tier 2';
  if (productId === APPSUMO_TIER3) return 'Appsumo Tier 3';
  return 'Appsumo Lifetime Plan'
};

const findMaxVideoLengthByProductName = (
  productName: string
): number | 'unlimited' => {
  if (productName === STARTER_PLAN) return 360 * 60;
  if (productName === CREATOR_PLAN) return 600 * 60;
  if (productName === PRO_PLAN) return 'unlimited';

  if (productName === APPSUMO_TIER1) return 10 * 60 * 60;
  if (productName === APPSUMO_TIER2) return 'unlimited';
  if (productName === APPSUMO_TIER3) return 'unlimited';
  return 10 * 60;
};

const findMaxSlideshowLengthByProductName = (productName: string): number => {
  if (productName === STARTER_PLAN) return 10 * 60;
  if (productName === CREATOR_PLAN) return 360 * 60;
  if (productName === PRO_PLAN) return 500 * 60;

  if (productName === APPSUMO_TIER1) return 10 * 60 * 60;
  if (productName === APPSUMO_TIER2) return 50 * 60 * 60;
  if (productName === APPSUMO_TIER3) return 100 * 60 * 60;
  return 5 * 60;
};
const findAvailableVideoLengthByProductName = (
  creditsInSeconds: number,
  productName: string
): number | 'unlimited' => {
  if (productName === CREATOR_PLAN) return 'unlimited';
  if (productName === PRO_PLAN) return 'unlimited';
  if (productName === APPSUMO_TIER2) return 'unlimited';
  if (productName === APPSUMO_TIER3) return 'unlimited';
  return creditsInSeconds;
};

export {
  findPlanNameByProductId,
  findPlanNameByAppSumoProductId,
  findMaxVideoLengthByProductName,
  findMaxSlideshowLengthByProductName,
  findAvailableVideoLengthByProductName
};
